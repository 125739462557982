
import { defineComponent, ref, onMounted } from "vue";
import Textarea from "primevue/textarea";
import useAuthentication from "../composables/useAuthentication";
import { userStore } from "../store/user-store";

export default defineComponent({
  name: "App",
  components: { Textarea },
  setup() {
    const description = ref();
    const edit = ref(false);
    const { isAuthenticated } = useAuthentication();
    const submitDescription = () => {
      userStore
        .updateUserDescription(1, description.value)
        .then((response: any) => {
          edit.value = false;
          description.value = response.data.description;
        });
    };
    onMounted(() => {
      userStore.getUserDescription(1).then((response: any) => {
        description.value = response;
      });
    });
    return { isAuthenticated, edit, description, submitDescription };
  },
});
