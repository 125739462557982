
import FileUpload from "../components/FileUpload.vue";
import Textarea from "primevue/textarea";

import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { axiosBlog } from "../api/axios";
import {
  ImageInterface,
  LightboxInterface,
} from "../interfaces/image.interface";
import { useConfirm } from "primevue/useconfirm";
import ConfirmPopup from "primevue/confirmpopup";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import useAuthentication from "../composables/useAuthentication";

import VueEasyLightbox from "vue-easy-lightbox";
import { log } from "console";

export default {
  name: "Gallery",
  components: {
    VueEasyLightbox,
    InputText,
    ConfirmPopup,
    FileUpload,
    Textarea,
  },
  props: {},
  setup() {
    const index = ref(0);
    const visible = ref(false);
    const show = () => {
      visible.value = true;
    };
    const handleHide = () => {
      visible.value = false;
    };
    const showImg = (i: number) => {
      index.value = i;
      visible.value = true;
    };
    const toast = useToast();
    const images = ref<LightboxInterface[]>([]);
    const galleryLocation = "/api/file/gallery/";
    const thumbnailSrc = "/api/file/thumbnails/";
    const isLoading = ref(true);
    const { isAuthenticated } = useAuthentication();
    const infoMsg = ref();
    const title = ref();
    const alt = ref();

    const changeImageInfo = async (item: any) => {
      const form: ImageInterface = {
        id: item.id,
        itemImageSrc: item.itemImageSrc,
        alt: alt.value,
        title: title.value,
      };
      try {
        let response;
        response = await axiosBlog.put("api/file/gallery/imageInfo", form);
        console.log("put", response);
        toast.add({
          severity: "success",
          summary: "Info aktualisiert",
          detail: "Bild " + response.data.id + " geupdated",
          life: 3000,
        });
      } catch (error: any) {
        console.log(error);
        toast.add({
          severity: "error",
          summary: "Info wurde nicht aktualisiert",
          detail: "Message Content",
          life: 3000,
        });
      }
    };
    const deleteImage = async (image: ImageInterface) => {
      const response = await axiosBlog.delete(
        galleryLocation + image.itemImageSrc
      );
      images.value = images.value.filter((galleryImg: any) => {
        return galleryImg.id !== image.id;
      });
      response.statusText === "OK"
        ? (infoMsg.value = "Bild erfolgreich gelöscht")
        : (infoMsg.value = "Bild konnte nicht gelöscht werden");
    };
    onMounted(() => {
      isLoading.value = true;
      axiosBlog.get(galleryLocation).then((fetchedImages: any) => {
        fetchedImages.data.map((fileName: any, index: number) => {
          axiosBlog
            .get(galleryLocation + "imageInfo/" + fileName)
            .then((imageInfo: any) => {
              images.value[index] = {
                id: imageInfo.data.id,
                src: fileName
                  ? galleryLocation + fileName
                  : galleryLocation + imageInfo.data.itemImageSrc,
                thumbnailSrc: thumbnailSrc + fileName,
                title: imageInfo.data.title ? imageInfo.data.title : "",
                alt: imageInfo.data.alt ? imageInfo.data.alt : "",
              };
              isLoading.value = false;
            });
        });
      });
    });

    const confirm = useConfirm();
    const confirmDeletion = (event: any, item: any) => {
      confirm.require({
        target: event.currentTarget,
        message: "Möchtest du das Bild wirklich löschen?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteImage(item);
        },
        reject: () => {
          //do nothing
        },
      });
    };

    return {
      isAuthenticated,
      isLoading,
      images,
      confirmDeletion,
      infoMsg,
      changeImageInfo,
      title,
      alt,
      galleryLocation,
      thumbnailSrc,
      index,
      visible,
      show,
      handleHide,
      showImg,
    };
  },
};
