
import Galleria from "primevue/galleria";
import FileUpload from "../components/FileUpload.vue";

import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { axiosBlog } from "../api/axios";
import { ImageInterface } from "../interfaces/image.interface";
import { useConfirm } from "primevue/useconfirm";
import ConfirmPopup from "primevue/confirmpopup";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import useAuthentication from "../composables/useAuthentication";
import { log } from "console";
import Lightbox from './Lightbox.vue';

export default {
  name: "Gallery",
  components: { Lightbox },
  props: {},
  setup() {
    const toast = useToast();
    const images = ref<ImageInterface[]>([]);
    const galleryLocation = "/api/file/gallery/";
    const thumbnailSrc = "/api/file/thumbnails/";
    const isLoading = ref(true);
    const { isAuthenticated } = useAuthentication();
    const infoMsg = ref();
    const title = ref();
    const alt = ref();

    const changeImageInfo = async (item: any) => {
      const form: ImageInterface = {
        id: item.id,
        itemImageSrc: item.itemImageSrc,
        alt: alt.value,
        title: title.value,
      };
      try {
        let response;
        response = await axiosBlog.put("api/file/gallery/imageInfo", form);
        console.log("put", response);
        toast.add({
          severity: "success",
          summary: "Info aktualisiert",
          detail: "Bild " + response.data.id + " geupdated",
          life: 3000,
        });
      } catch (error: any) {
        console.log(error);
        toast.add({
          severity: "error",
          summary: "Info wurde nicht aktualisiert",
          detail: "Message Content",
          life: 3000,
        });
      }
    };
    const deleteImage = async (image: ImageInterface) => {
      const response = await axiosBlog.delete(
        galleryLocation + image.itemImageSrc
      );
      images.value = images.value.filter((galleryImg: any) => {
        return galleryImg.id !== image.id;
      });
      response.statusText === "OK"
        ? (infoMsg.value = "Bild erfolgreich gelöscht")
        : (infoMsg.value = "Bild konnte nicht gelöscht werden");
    };
    onMounted(() => {
      isLoading.value = true;
      axiosBlog.get(galleryLocation).then((fetchedImages: any) => {
        fetchedImages.data.map((fileName: any, index: number) => {
          axiosBlog
            .get(galleryLocation + "imageInfo/" + fileName)
            .then((imageInfo: any) => {
              images.value[index] = {
                id: imageInfo.data.id,
                itemImageSrc: fileName ? fileName : imageInfo.data.itemImageSrc,
                thumbnailImageSrc: fileName,
                title: imageInfo.data.title ? imageInfo.data.title : "",
                alt: imageInfo.data.alt ? imageInfo.data.alt : "",
              };
              isLoading.value = false;
            });
        });
      });
    });
    const responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 5,
      },
      {
        breakpoint: "960px",
        numVisible: 4,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
      },
    ];

    const confirm = useConfirm();
    const confirmDeletion = (event: any, item: any) => {
      confirm.require({
        target: event.currentTarget,
        message: "Möchtest du das Bild wirklich löschen?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteImage(item);
        },
        reject: () => {
          //do nothing
        },
      });
    };

    return {
      isAuthenticated,
      isLoading,
      images,
      responsiveOptions,
      confirmDeletion,
      infoMsg,
      changeImageInfo,
      title,
      alt,
      galleryLocation,
      thumbnailSrc,
    };
  },
};
